import React from 'react';
import {
  Grid,
  Paper,
  Typography,
  Box,
  Card,
  CardContent,
} from '@mui/material';
import {
  ShoppingCart as OrdersIcon,
  AttachMoney as RevenueIcon,
  TrendingUp as GrowthIcon,
  Warning as PendingIcon,
} from '@mui/icons-material';
import { formatCurrency } from '../../utils/currency';

const StatCard = ({ title, value, icon, color, isCurrency = false }: any) => (
  <Card>
    <CardContent>
      <Box sx={{ display: 'flex', alignItems: 'center', mb: 2 }}>
        {React.cloneElement(icon, { sx: { color, fontSize: 40 } })}
      </Box>
      <Typography variant="h5" component="div">
        {isCurrency ? formatCurrency(Number(value)) : value}
      </Typography>
      <Typography color="text.secondary">
        {title}
      </Typography>
    </CardContent>
  </Card>
);

export default function Dashboard() {
  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      
      <Grid container spacing={3}>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Total Orders"
            value="150"
            icon={<OrdersIcon />}
            color="#1976d2"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Revenue"
            value={15350}
            icon={<RevenueIcon />}
            color="#2e7d32"
            isCurrency={true}
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Growth"
            value="+23%"
            icon={<GrowthIcon />}
            color="#ed6c02"
          />
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <StatCard
            title="Pending"
            value="12"
            icon={<PendingIcon />}
            color="#d32f2f"
          />
        </Grid>
      </Grid>

      <Box sx={{ mt: 4 }}>
        <Typography variant="h5" gutterBottom>
          Recent Orders
        </Typography>
        <Paper sx={{ p: 2 }}>
          {/* Order table will go here */}
          <Typography>Coming soon...</Typography>
        </Paper>
      </Box>
    </Box>
  );
}
