export const formatCurrency = (amount: number): string => {
    return new Intl.NumberFormat('ar-MA', {
        style: 'currency',
        currency: 'MAD',
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
    }).format(amount);
};

export const parseCurrency = (value: string): number => {
    // Rimuove il simbolo della valuta e gli spazi
    const cleanValue = value.replace(/[MAD\s]/g, '');
    // Converte la stringa in numero
    return Number(cleanValue);
};
